import { memo, useState, useEffect } from 'react';
// @mui
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
// components
import Logo from 'src/components/logo';
import { NavSectionHorizontal } from 'src/components/nav-section';
import { useSettingsContext } from 'src/components/settings';
//
import { useNavData } from './config-navigation';
import { HeaderShadow, LanguagePopover } from '../_common';
import NavTools from './nav-tools';

// ----------------------------------------------------------------------

function NavHorizontal() {
    const theme = useTheme();

    const settings = useSettingsContext();

    const navData = useNavData();
    const dateToShow = new Date();
    const timeZoneOffset = Math.abs(dateToShow.getTimezoneOffset());

    const hours = Math.floor(timeZoneOffset / 60);
    const minutes = timeZoneOffset % 60;
    const gmt = `GMT+ ${hours}:${minutes}`;
    console.log(dateToShow, timeZoneOffset); // Mon Nov 28 2022 13:15:59 GMT+0200 (South Africa Standard Time)

    const [time, setTime] = useState(new Date());
    const [timeZone, setTimeZone] = useState(gmt);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <AppBar
            component="nav"
            position="relative"
            sx={{
                bgcolor: theme.palette.background.default
            }}
        >
            {/* <Container maxWidth={settings.themeStretch && false} sx={{ px: '130px !important' }}> */}
            <Toolbar
                sx={{
                    bgcolor: theme.palette.background.topbar,
                    px: '0px !important',
                    py: 0.5,
                    minHeight: 'auto !important',
                    '& a .MuiListItemText-root span': {
                        fontSize: '15px !important',
                        fontWeight: 700,
                        color: 'white'
                    },
                    '& .MuiListItemText-root': {
                        margin: 0
                    },
                    '& a:nth-of-type(6) .MuiButtonBase-root': {
                        width: '65px'
                    }
                }}
            >
                <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{
                    display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                }}>
                    <Typography
                        color="#fff"
                        className=""
                        sx={{ fontSize: '0.875rem' }}
                    >
                        {time.toLocaleTimeString([], { hour12: false })} {timeZone}
                    </Typography>
                    <LanguagePopover />
                    {/* </Stack> */}
                </Container>
            </Toolbar>
            <Toolbar
                sx={{
                    px: '0px !important',
                    py: 1.5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& a .MuiListItemText-root span': {
                        fontSize: '15px !important',
                        fontWeight: 700,
                        color: 'white'
                    },
                    '& .MuiListItemText-root': {
                        margin: 0
                    },
                    '& a:nth-of-type(6) .MuiButtonBase-root': {
                        width: '65px'
                    }
                }}
            >
                <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{
                    display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                }}>
                    <Logo sx={{ mr: 2.5 }} />
                    {/* <Stack direction="row" alignItems="center" flexGrow={1} justifyContent="space-between"> */}


                    <NavTools />
                    {/* </Stack> */}
                </Container>
            </Toolbar>
            <Toolbar
                sx={{
                    bgcolor: theme.palette.background.navBG,
                    px: '0px !important',
                    py: 1,
                    display: 'flex',
                    minHeight: 'auto !important',
                    justifyContent: 'space-between',
                    '& a .MuiListItemText-root span': {
                        fontSize: '12px !important',
                        textTransform: 'uppercase',
                        color: 'white',
                        lineHeight: 'normal'
                    },
                    '& .MuiListItemText-root': {
                        margin: 0
                    },
                    '& a:nth-of-type(6) .MuiButtonBase-root': {
                        width: '70px'
                    }
                }}
            >
                <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{
                    display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                }}>
                    <NavSectionHorizontal data={navData} justifyContent='space-between' alignItems='center' gap={0.5} />
                </Container>
            </Toolbar>
            {/* </Container> */}
            <HeaderShadow />
        </AppBar>
    );
}

export default memo(NavHorizontal);
